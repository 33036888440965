$enable-negative-margins: true;
$link-decoration: none;
$form-label-margin-bottom: 0.25rem;
$alert-padding-x: 0.5rem;
$alert-padding-y: 0.5rem;
$card-border-width: 0;
$modal-header-padding: 0.5rem 1rem;
$form-feedback-margin-top: 0.25rem;
$input-btn-focus-width: 0;
$btn-focus-box-shadow: none;
$btn-active-box-shadow: none;
$toast-header-color: #fff;
$toast-header-background-color: #333;
$toast-header-border-color: transparent;
$primary: #4fbbee;
$btn-border-radius: 10rem;
$btn-border-radius-sm: 10rem;
$btn-border-radius-lg: 10rem;
$btn-padding-x: 1rem; // 0.75rem;
$btn-padding-x-sm: 1.5rem; // 1.125rem;
$btn-padding-x-lg: 2rem; // 1.5rem;