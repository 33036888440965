.poweredBy {
    text-align: center;
    font-size: 0.75rem;
    color: #999;
    width: 100%;
}
.poweredBy a {
    color: #808080;
    text-decoration: none;
}
.poweredBy a:hover {
    color: #808080;
}